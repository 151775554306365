<template>
<v-app>
    <app-header></app-header>
    <div  data-spy="scroll" data-target="#navbar-app" data-offset="0">
        <div id="accueil">
            <Accueil></Accueil>
        </div>
        <!-- <div>
            <Timer></Timer>
        </div> -->
        <div id="propos">
            <Axe2></Axe2>
        </div>
        <div id="">
            <thematique></thematique>
        </div>
        <div id="axe">
            <scientifique/>
        </div>
        <div id="agenda">
            <agenda class="mt-5" v-on:slidenum="slidenum"/>
        </div>
        <div id="speakers">
            <speakers class="mt-5" :slideshow="slideshow"/>
        </div>
        <div id="sponsors">
            <sponsors/>
        </div>
    </div>
    <app-footer></app-footer>
</v-app>
</template>

<script>
    import AppHeader from './appHeader.vue';
    // import Timer from './landing/timer.vue';
    import thematique from './landing/thematique.vue';
    import Accueil from './landing/accueil.vue';
    import scientifique from './landing/scientifique.vue';
    import Axe2 from './landing/axe2.vue';
    import agenda from './landing/agenda.vue';
    import speakers from './landing/speakers.vue';
    import sponsors from './landing/sponsors.vue';
    import AppFooter from './appFooter.vue';

    export default {
        components: { AppHeader ,AppFooter , thematique, Accueil, Axe2, scientifique, speakers, agenda, sponsors },

        data: () => ({
            scrollPosition: null,
            slideshow:"",
            dialog:false,
            vid:"",
            audio:true
        }),
        methods: {
            updateScroll() {
                this.scrollPosition = window.scrollY
            },
            goTo(path){
                this.$router.push(path)
            },
            click: function() {
                this.$refs.inscriComponent.setValue();
            },
            slidenum(num){
                this.slideshow=num
            },
        },
        mounted() {
            window.addEventListener('scroll', this.updateScroll);
            this.slideshow=0  
        },

    };
</script>
<style >

</style>