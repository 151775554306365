<template>
    <div class="">
        <v-timeline align-top dense>
        <v-row>
        <v-col class="titreline"  >
            <b  >ATELIERS</b>
        </v-col>
        </v-row>
        <v-timeline-item  color="amber darken-4" small>
            <v-row class="pt-1">
                <v-col cols="12">
                <strong>9:00-10:00</strong>
                </v-col>
            </v-row>
            <v-row>     
                <v-col cols="12" align="left" md="10" sm="12">
                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                        <v-avatar>
                        <img src="/speakers/iraqi.png" alt="Fabienne">
                        </v-avatar>
                        <b class="ml-2">Pr Hinde Iraqi</b>
                    </a><br>
                    <span class="sessionTitre ml-14">A1 : Cycles glycémiques des patients diabétiques de type 2 :
                        les indications ... les pièges.</span>
                    <v-row class="mt-1">
                        <v-col cols="12" md="12" sm="12" align="left" class="" style="text-justify: inter-word;">
                          <b style="color:#1976D2">Modérateurs :</b><b class="ml-1" style="color:#ABA09C"> M.Belfatmi -</b>
                                <b class="ml-1" style="color:#ABA09C"> F.Nibani</b>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="2" sm="12" align="right">
                   <img width="120" src="../../assets/sponsors/sponsor-11.png" alt="Fabienne"> 
                </v-col>
                <v-col cols="12" align="left" >
                    <a href="#speakers" @click="slideTo(9)" style="text-decoration:none">
                        <v-avatar>
                            <img src="/speakers/khlafa.png" alt="Fabienne">
                        </v-avatar>
                        <b class="ml-2">Dr Mohamed Khlafa</b>
                    </a><br>
                    <span class="sessionTitre ml-14">A2 : L’insulinothérapie provisoire ; quand et comment?</span>
                    <v-row class="mt-1">
                        <v-col cols="12" md="12" sm="12" align="left" class="" style="text-justify: inter-word;">
                          <b style="color:#1976D2">Modérateurs :</b><b class="ml-1" style="color:#ABA09C"> El H.El Mahdaoui -</b>
                                <b class="ml-1" style="color:#ABA09C"> M.K.Benjelloun</b>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" align="left" >
                    <a href="#speakers" @click="slideTo(1)" style="text-decoration:none">
                        <v-avatar>
                            <img src="/speakers/saada.jpg" alt="Fabienne">
                        </v-avatar>
                        <b class="ml-2">Dr Abdellatif Saada</b>
                    </a><br>
                    <span class="sessionTitre ml-14">A3 : L’éducation thérapeutique & qualité de vie du patient diabétique</span>
                    <v-row class="mt-1">
                        <v-col cols="12" md="12" sm="12" align="left" class="" style="text-justify: inter-word;">
                          <b style="color:#1976D2">Modérateurs :</b><b class="ml-1" style="color:#ABA09C">A.Samir -</b>
                                <b class="ml-1" style="color:#ABA09C"> A.Lachhab</b>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-timeline-item>
        <v-row>
            <v-col cols="10" class="titreline" >
                <b >SÉANCES PLÉNIÈRES</b>
            </v-col>
        </v-row>
        <v-timeline-item  color="amber darken-4" small>
            <v-row class="pt-1">
                <v-col cols="12">
                    <strong>10:00-10:30</strong>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" align="left" >
                    <a href="#speakers" @click="slideTo(10)" style="text-decoration:none">
                        <v-avatar>
                        <img src="/speakers/thevenieau.jpg" alt="Fabienne">
                        </v-avatar>
                        <b class="ml-2">Dr Dominique Thevenieau</b>
                    </a><br>
                    <span class="sessionTitre ml-14">Quelle alimentation souhaitée pour nos adolescents diabétiques ?</span>
                    <v-row class="mt-1">
                        <v-col cols="12" md="12" sm="12" align="left" class="" style="text-justify: inter-word;">
                          <b style="color:#1976D2">Modérateurs :</b><b class="ml-1" style="color:#ABA09C">A.Bakri -</b>
                                <b class="ml-1" style="color:#ABA09C"> H.Chahid -</b>
                                <b class="ml-1" style="color:#ABA09C"> N.Essekkat</b>
                        </v-col>
                    </v-row>
                </v-col>
          </v-row>
        </v-timeline-item>
        <v-timeline-item  color="indigo darken-3" small>
            <v-row class="pt-1">
                <v-col cols="12">
                    <strong>10:30-11:00</strong>
                </v-col>
                <v-col  cols="11" align="center" >
                    <strong style="color:#F38F3C">PAUSE CAFÉ - VISITE DES STANDS</strong>
                </v-col>
            </v-row>
        </v-timeline-item>
        <v-timeline-item  color="amber darken-4" small>
            <v-row class="pt-1">
                <v-col cols="12">
                    <strong>11:00-11:30</strong>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" align="left" >
                    <a href="#speakers" @click="slideTo(14)" style="text-decoration:none">
                        <v-avatar>
                        <img src="/speakers/sbai.jpg" alt="Fabienne">
                        </v-avatar>
                        <b class="ml-2">Pr Abdenabi Sbai</b>
                    </a><br>
                    <span class="sessionTitre ml-14">La souveraineté médicale et pharmaceutique : Le management du chantier de la protection sociale au Maroc</span>
                    <v-row class="mt-1">
                        <v-col cols="12" md="12" sm="12" align="left" class="" style="text-justify: inter-word;">
                          <b style="color:#1976D2">Modérateurs :</b><b class="ml-1" style="color:#ABA09C"> A.El Azrak -</b>
                                <b class="ml-1" style="color:#ABA09C"> D.Rochd</b>
                        </v-col>
                    </v-row>
                </v-col>
          </v-row>
        </v-timeline-item>
        <v-timeline-item  color="amber darken-4" small>
            <v-row class="pt-1">
                <v-col cols="12">
                <strong>11:30-12:00</strong>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" align="left" >
                    <a href="#speakers" @click="slideTo(3)" style="text-decoration:none">
                        <v-avatar>
                        <img src="/speakers/heikel.png" alt="Fabienne">
                        </v-avatar>
                        <b class="ml-2">Pr. Jaafar Heikel</b>
                    </a><br>
                    <span class="sessionTitre ml-14">Troubles nutritionnels du sujets âgés : Diagnostic et prise en charge</span>
                    <v-row class="mt-1">
                        <v-col cols="12" md="12" sm="12" align="left" class="" style="text-justify: inter-word;">
                          <b style="color:#1976D2">Modérateurs :</b><b class="ml-1" style="color:#ABA09C"> Mohamed Belfatmi -</b>
                                <b class="ml-1" style="color:#ABA09C"> F.Nibani</b>
                        </v-col>
                    </v-row>
                </v-col>
          </v-row>
        </v-timeline-item>
        <v-timeline-item  color="amber darken-4" small>
            <v-row class="pt-1">
                <v-col cols="12">
                <strong>12:00-12:30</strong>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" align="left" >
                    <a href="#speakers" @click="slideTo(15)" style="text-decoration:none">
                        <v-avatar>
                            <img src="/speakers/khayat.jpg" alt="Fabienne">
                        </v-avatar>
                        <b class="ml-2">Pr Selma Siham El Khayat</b>
                    </a><br>
                    <span class="sessionTitre ml-14">Prise en charge de l’infection urinaire en 2022</span>
                    <v-row class="mt-1">
                        <v-col cols="12" md="12" sm="12" align="left" class="" style="text-justify: inter-word;">
                          <b style="color:#1976D2">Modérateurs :</b><b class="ml-1" style="color:#ABA09C"> M.Benchekroune -</b>
                                <b class="ml-1" style="color:#ABA09C"> A.Bakri </b>
                                <b class="ml-1" style="color:#ABA09C">- N.Essekkat</b>
                        </v-col>
                    </v-row>
                </v-col>
          </v-row>
        </v-timeline-item>
        <v-timeline-item  color="amber darken-4" small>
            <v-row class="pt-1">
                <v-col cols="12">
                <strong>12:30-13:00</strong>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" align="left" >
                    <a href="#speakers" @click="slideTo(0)" style="text-decoration:none">
                        <v-avatar>
                        <img src="/speakers/benabdennbi.jpg" alt="Fabienne">
                        </v-avatar>
                        <b class="ml-2">Dr Faouzi Benabdennbi</b>
                    </a><br>
                    <span class="sessionTitre ml-14">Relation médecin malade : du paternalisme au partenariat</span>
                    <v-row class="mt-1">
                        <v-col cols="12" md="12" sm="12" align="left" class="" style="text-justify: inter-word;">
                          <b style="color:#1976D2">Modérateurs :</b><b class="ml-1" style="color:#ABA09C"> S.Rizk -</b>
                                <b class="ml-1" style="color:#ABA09C"> K.Hamamsi</b>
                        </v-col>
                    </v-row>
                </v-col>
          </v-row>
        </v-timeline-item>
        <v-timeline-item  color="indigo darken-3" small>
            <v-row class="pt-1">
                <v-col cols="12">
                    <strong>13:00-15:00</strong>
                </v-col>
                <v-col  cols="11" align="center" >
                    <strong style="color:#F38F3C">DÉJEUNER</strong>
                </v-col>
            </v-row>
        </v-timeline-item>
        <v-timeline-item  color="amber darken-4" small>
            <v-row class="">
                <v-col cols="12">
                    <strong>15:15-15:30</strong>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" align="left" >
                    <a href="#speakers" @click="slideTo(4)" style="text-decoration:none">
                        <v-avatar>
                        <img src="/speakers/monnier.png" alt="Fabienne">
                        </v-avatar>
                        <b class="ml-2">Pr Louis Monnier (Université de Montpellier)</b>
                    </a><br>
                    <span class="sessionTitre ml-14">Introduction : Efficience et précision en diabétologie : symphonie inachevée ?</span>
                    <v-row class="mt-1">
                        <v-col cols="12" md="12" sm="12" align="left" class="" style="text-justify: inter-word;">
                            <b style="color:#1976D2">Modérateurs :</b><b class="ml-1" style="color:#ABA09C"> A.El Azrak -</b>
                                <b class="ml-1" style="color:#ABA09C"> M.Belfatmi -</b>
                                <b class="ml-1" style="color:#ABA09C"> D.Rochd -</b>
                                <b class="ml-1" style="color:#ABA09C"> M.K.Benjelloun</b>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-timeline-item>
        <v-timeline-item  color="amber darken-4" small>
            <v-row class="pt-1">
                <v-col cols="12">
                    <strong>15:30-16:00</strong>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" align="left" >
                    <a href="#speakers" @click="slideTo(11)" style="text-decoration:none">
                        <v-avatar>
                        <img src="/speakers/eric.jpg" alt="Fabienne">
                        </v-avatar>
                        <b class="ml-2">Pr Eric Renard (Montpellier) ( Visioconférence )</b>
                    </a><br>
                    <span class="sessionTitre ml-14">L’insulinothérapie dans le diabète de type 1 : un paysage en constante évolution</span>
                </v-col>
          </v-row>
        </v-timeline-item>
        <v-timeline-item  color="amber darken-4" small>
            <v-row class="pt-1">
                <v-col cols="12">
                    <strong>16:00-16:30</strong>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" align="left" >
                    <a href="#speakers" @click="slideTo(6)" style="text-decoration:none">
                        <v-avatar>
                        <img src="/speakers/bernard.png" alt="Fabienne">
                        </v-avatar>
                        <b class="ml-2">Pr Bernard Bauduceau (Hôpital Bégin, Saint-Mandé)</b>
                    </a><br>
                    <span class="sessionTitre ml-14">Recommandations thérapeutiques dans le diabète de type 2 : entre spécificités
                    géographiques et universalité</span>
                </v-col>
          </v-row>
        </v-timeline-item>
        <v-timeline-item  color="amber darken-4" small>
            <v-row class="pt-1">
                <v-col cols="12">
                    <strong>16:30-17:00</strong>
                </v-col>
            </v-row>
             <v-row>
                <v-col cols="12" align="left" >
                    <a href="#speakers" @click="slideTo(23)" style="text-decoration:none">
                        <v-avatar>
                        <img src="/speakers/bordier.png" alt="Fabienne">
                        </v-avatar>
                        <b class="ml-2">Pr. Lyse Bordier (Hôpital Bégin, Saint-Mandé)</b>
                    </a><br>
                    <span class="sessionTitre ml-14">Évolution des cibles thérapeutiques chez le patient diabétique au cours des dernières
                        années
                    </span>
                </v-col>
            </v-row>
        </v-timeline-item>
        <v-timeline-item  color="indigo darken-3" small>
            <v-row class="pt-1">
                <v-col cols="12">
                    <strong>17:00-17:30</strong>
                </v-col>
                <v-col  cols="11" align="center" >
                    <strong style="color:#F38F3C">PAUSE CAFÉ - VISITE DES STANDS</strong>
                </v-col>
            </v-row>
        </v-timeline-item>
        <v-timeline-item  color="amber darken-4" small>
            <v-row class="pt-1">
                <v-col cols="12">
                    <strong>17:30-18:00</strong>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" align="left" >
                    <a href="#speakers" @click="slideTo(4)" style="text-decoration:none">
                        <v-avatar>
                        <img src="/speakers/monnier.png" alt="Fabienne">
                        </v-avatar>
                        <b class="ml-2">Pr Louis Monnier (Université de Montpellier)</b>
                    </a><br>
                    <span class="sessionTitre ml-14">Au-delà des insulines actuelles : des réalisations prometteuses et quelques désillusions</span>
                </v-col>
          </v-row>
        </v-timeline-item>
        <v-timeline-item  color="amber darken-4" small>
            <v-row class="pt-1">
                <v-col cols="12">
                    <strong>18:00-18:30</strong>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" align="left" >
                    <a href="#speakers" @click="slideTo(5)" style="text-decoration:none">
                        <v-avatar>
                        <img src="/speakers/jean.png" alt="Fabienne">
                        </v-avatar>
                        <b class="ml-2">Pr Jean-Louis Schlienger (Université de Strasbourg)</b>
                    </a><br>
                    <span class="sessionTitre ml-14">Les doubles agonistes des récepteurs du GIP et du GLP-1 : le futur des incrétinomimétiques</span>
                </v-col>
          </v-row>
        </v-timeline-item>
        <v-timeline-item  color="amber darken-4" small>
            <v-row class="pt-1">
                <v-col cols="12">
                    <strong>18:30-19:00</strong>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" align="left" >
                    <span class="sessionTitre ml-14">Discussion générale et questions </span>
                </v-col>
          </v-row>
        </v-timeline-item>
        <v-timeline-item  color="indigo darken-3" small>
            <v-row class="pt-1">
                <v-col cols="12">
                    <strong>à partir de 19h00</strong>
                </v-col>
                <v-col  cols="11" align="center" >
                    <strong style="color:#F38F3C">INAUGURATION OFFICIELLE DU CONGRÈS</strong>
                </v-col>
            </v-row>
        </v-timeline-item>
        </v-timeline>
    </div>
</template>
<script>
export default {

    metaInfo() {
        return{
        
      }
    },
    methods: {
        slideTo(num){
            this.$emit('gotoslide',num)
        }
    },
    
}
</script>
<style >

.sessionTitre{
    color: #007DA4;
}
.titreline{
    margin-left: 60px!important;
    color: #F28F3D;
    font-size:1.5em 
}
.titreline2{
    margin-left: 60px!important;
    color: #1B84A5;
    font-size:1.5em 
}

@media all and (max-width: 768px) {
  
  .horaire{
      font-size: 0.9em;
      padding-left: 1px!important;
    }
    .titreline{
        margin-left: 60px!important;
        color: #F28F3D;
        font-size:1em 
    }
    .titreline2{
        margin-left: 20px!important;
        color: #1B84A5;
        font-size:1.2em 
    }

    .sessionTitre{
        font-size: 0.9em;
    }
  
}


@media all and (max-width: 280px) {
  
  
    .sessionTitre{
        font-size: 0.6em;
    }
  
}


</style>