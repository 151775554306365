<template>
	<div>
		<div class="social" style="text-align:right">
			<v-card class="social flex pa-1" max-width="40px" color="white">
				<a href="https://web.facebook.com/Congr%C3%A8s-National-de-lADN-115760883680227" target="_blank"><img height="29" width="29" class="img-responsive mb-1" src="../assets/F.png" ></a> 
				<a href="https://www.youtube.com/channel/UCUva7z9km4vmaZemVQ69Wsw" target="_blank"> <img height="29" width="29" class="img-responsive " src="../assets/Y.png" ></a> 
			</v-card>
		</div>
		<v-parallax src="https://drive.google.com/uc?id=1S9iCf4bShWUm_ZDwqst4F4dKMzkVXNsV&export=download" class="footpara" >
			<div class="footparashad">
				<v-row class="safariFlex">
					<v-col cols="12" md="3" sm="12"  align="center" class="mt-5 ">
					
					</v-col>
					<v-col cols="12" md="3" sm="12"  align="center" class="mt-5 ">
						<a href="https://ascrea.ma/" target="_blanc"><img  class="img-responsive Logofoter" src="../assets/logo.png" height="100" width="280" ></a>
					</v-col>
					
				</v-row>
			</div>
		</v-parallax>  
	</div>
</template>

<script>
export default {

  data: () => ({
  }),
  methods: {
    
},
mounted() {
},

};
</script>
<style >

</style>