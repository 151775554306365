import Vue from 'vue'
import App from './App.vue'
import 'babel-polyfill'
import vuetify from './plugins/vuetify';
import 'bootstrap';
import './assets/app.scss';
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'font-awesome/css/font-awesome.min.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@mdi/font/css/materialdesignicons.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from "./router"
import AOS from 'aos'
import 'aos/dist/aos.css'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

import VueTyperPlugin from 'vue-typer';

AOS.init();

Vue.use(VueTyperPlugin)

 
Vue.use(PerfectScrollbar)



window.axios = require('axios');



Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueAxios, axios)


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'



//countdown
import VueCountdown from '@chenfengyuan/vue-countdown';
Vue.component(VueCountdown.name, VueCountdown);

//shadow image
import ImageShadow from 'vue-image-shadow';
Vue.component('image-shadow', ImageShadow)

new Vue({
  vuetify,
  router,
  icons: {
    iconfont: ['fa','mdi'],
  },
  created () {
    AOS.init()
  },
  render: h => h(App)
}).$mount('#app')
