<template>
    <div class="">
        <v-timeline align-top dense>
        <v-timeline-item  color="amber darken-4" small>
            <v-row class="pt-1">
                <v-col cols="12">
                <strong>8:45-9:30</strong>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" align="left" >
                    <a href="#speakers" @click="slideTo(9)" style="text-decoration:none">
                        <v-avatar>
                        <img src="/speakers/khlafa.png" alt="Fabienne">
                        </v-avatar>
                        <b class="ml-2">Dr Mohamed Khlafa</b>
                    </a><br>
                    <span class="sessionTitre ml-14">Le diabétique âgé ; quelle prise en charge ?</span>
                    <v-row class="mt-1">
                        <v-col cols="12" md="12" sm="12" align="left" class="" style="text-justify: inter-word;">
                          <b style="color:#1976D2">Modérateurs :</b><b class="ml-1" style="color:#ABA09C"> A.Samir -</b>
                                <b class="ml-1" style="color:#ABA09C"> A.Lachhab </b>
                        </v-col>
                    </v-row>
                </v-col>
          </v-row>
        </v-timeline-item>
        <v-timeline-item  color="amber darken-4" small>
            <v-row class="pt-1">
                <v-col cols="12">
                <strong>09:30-10:00</strong>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6" sm="12" align="left" >
                    <a href="#speakers" @click="slideTo(0)" style="text-decoration:none">
                        <v-avatar>
                        <img src="/speakers/morjane.png" alt="Fabienne">
                        </v-avatar>
                        <b class="ml-2">Pr Asmaa Morjan</b>
                    </a><br>
                    <span class="sessionTitre ml-14">Statut de la Vitamine D et l’équilibre diabétique</span>
                    <v-row class="mt-1">
                        <v-col cols="12" md="12" sm="12" align="left" class="" style="text-justify: inter-word;">
                          <b style="color:#1976D2">Modérateurs :</b><b class="ml-1" style="color:#ABA09C"> F.Nibani -</b>
                                <b class="ml-1" style="color:#ABA09C"> A.Belhouari </b>
                        </v-col>
                    </v-row>
                </v-col>
          </v-row>
        </v-timeline-item>
        <v-timeline-item  color="amber darken-4" small>
            <v-row class="pt-1">
                <v-col cols="12">
                <strong>10:00-10:30</strong>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="10" sm="12"  align="left" >
                    <a href="#speakers" @click="slideTo(8)" style="text-decoration:none">
                        <v-avatar>
                        <img src="/speakers/kevorkian.jpg" alt="Fabienne">
                        </v-avatar>
                        <b class="ml-2">Dr Jean-Philippe Kevorkian</b>
                    </a><br>
                    <span class="sessionTitre ml-14">Diagnostic et traitement d’une ostéite qui complique une plaie de pied diabétique :
                        le Diabétologue est-il inéluctablement dépendant du Radiologue et du Chirurgien ?</span>
                    <v-row class="mt-1">
                        <v-col cols="12" md="12" sm="12" align="left" class="" style="text-justify: inter-word;">
                            <b style="color:#1976D2">Modérateurs :</b><b class="ml-1" style="color:#ABA09C"> M.Belfatmi -</b>
                                <b class="ml-1" style="color:#ABA09C"> M.K.Benjelloun - </b>
                                <b class="ml-1" style="color:#ABA09C"> K.Hamamsi</b>
                        </v-col>
                    </v-row>
                </v-col>
          </v-row>
        </v-timeline-item>
        <v-timeline-item  color="amber darken-4" small>
            <v-row class="pt-1">
                <v-col cols="12">
                <strong>10:30-11:00</strong>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="10" sm="12" align="left" >
                    <a href="#speakers" @click="slideTo(15)" style="text-decoration:none">
                        <v-avatar>
                        <img src="/speakers/siham.jpg" alt="Fabienne">
                        </v-avatar>
                        <b class="ml-2">Pr Siham El Aziz</b>
                    </a><br>
                    <span class="sessionTitre ml-14">Approche clinique et thérapeutique dans la gestion précoce des
                        complications cardio-rénales du DT2</span>
                    <v-row class="mt-1">
                        <v-col cols="12" md="12" sm="12" align="left" class="" style="text-justify: inter-word;">
                            <b style="color:#1976D2">Modérateurs :</b><b class="ml-1" style="color:#ABA09C"> A.El Azrak -</b>
                                <b class="ml-1" style="color:#ABA09C"> D.Rochd </b>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="2" sm="12" align="right">
                   <img width="120" src="../../assets/sponsors/sponsor-12.png" alt="Fabienne"> 
                </v-col>
          </v-row>
        </v-timeline-item>
        <v-timeline-item  color="indigo darken-3" small>
            <v-row class="pt-1">
                <v-col cols="12">
                    <strong>11:00-11:30</strong>
                </v-col>
                <v-col  cols="11" align="center" >
                    <strong style="color:#F38F3C">PAUSE CAFÉ - VISITE DES STANDS</strong>
                </v-col>
            </v-row>
        </v-timeline-item>
        <v-timeline-item  color="amber darken-4" small>
            <v-row class="pt-1">
                <v-col cols="12">
                <strong>11:30-12:00</strong>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12"  md="10" sm="12" align="left" >
                    <a href="#speakers" @click="slideTo(12)" style="text-decoration:none">
                        <v-avatar>
                        <img src="/speakers/sonnet.jpg" alt="Fabienne">
                        </v-avatar>
                        <b class="ml-2">Dr Emmanuel Sonnet (CHU Brest)</b>
                    </a><br>
                    <span class="sessionTitre ml-14">Télésurveillance des patients diabétiques: retour d'expériences d'un centre français</span>
                    <v-row class="mt-1">
                        <v-col cols="12" md="12" sm="12" align="left" class="" style="text-justify: inter-word;">
                            <b style="color:#1976D2">Modérateurs :</b><b class="ml-1" style="color:#ABA09C"> A.Bakri -</b>
                                <b class="ml-1" style="color:#ABA09C"> N.Essekkat</b>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="2" sm="12" align="right">
                   <img width="120" src="../../assets/sponsors/sponsor-11.png" alt="Fabienne"> 
                </v-col>
          </v-row>
        </v-timeline-item>
        <v-timeline-item  color="amber darken-4" small>
            <v-row class="pt-1">
                <v-col cols="12">
                <strong>12:00-12:30</strong>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="10" sm="12" align="left" >
                    <a href="#speakers" @click="slideTo(2)" style="text-decoration:none">
                        <v-avatar>
                        <img src="/speakers/tazi.png" alt="Fabienne">
                        </v-avatar>
                        <b class="ml-2">Dr Otman Tazi</b>
                    </a><br>
                    <span class="sessionTitre ml-14">L’intensification de l’insulinothérapie: La simplicité au coeur de la prise en charge</span>
                    <v-row class="mt-1">
                        <v-col cols="12" md="12" sm="12" align="left" class="" style="text-justify: inter-word;">
                            <b style="color:#1976D2">Modérateurs :</b><b class="ml-1" style="color:#ABA09C"> M.Benchekroune -</b>
                                <b class="ml-1" style="color:#ABA09C"> F.Nibani</b>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="2" sm="12" align="right">
                   <img width="120" src="../../assets/sponsors/sponsor-08.png" alt="Fabienne"> 
                </v-col>
            </v-row>
        </v-timeline-item>
        <v-timeline-item  color="amber darken-4" small>
            <v-row class="pt-1">
                <v-col cols="12">
                    <strong>12:30-13:00</strong>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="10" sm="12" align="left" >
                    <a href="#speakers" @click="slideTo(19)" style="text-decoration:none">
                        <v-avatar>
                        <img src="/speakers/nassar.jpg" alt="Fabienne">
                        </v-avatar>
                        <b class="ml-2">Pr Kawtar Nassar</b>
                    </a><br>
                    <span class="sessionTitre ml-14">Prise en charge de la douleur neuropathique</span>
                    <v-row class="mt-1">
                        <v-col cols="12" md="12" sm="12" align="left" class="" style="text-justify: inter-word;">
                            <b style="color:#1976D2">Modérateurs :</b><b class="ml-1" style="color:#ABA09C"> S.Rizk -</b>
                                <b class="ml-1" style="color:#ABA09C"> H.Chahid -</b>
                                <b class="ml-1" style="color:#ABA09C"> O.Nour</b>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="2" sm="12" align="right">
                   <img width="120" src="../../assets/sponsors/sponsor-32.png" alt="Fabienne"> 
                   <img width="120" src="../../assets/spon-06.jpg" alt="Fabienne"> 
                </v-col>
          </v-row>
        </v-timeline-item>
        <v-timeline-item  color="amber darken-4" small>
            <v-row class="pt-1">
                <v-col cols="12">
                    <strong>13:00-13:30</strong>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="10" sm="12" align="left" >
                    <a href="#speakers" @click="slideTo(13)" style="text-decoration:none">
                        <v-avatar>
                        <img src="/speakers/iraqi.png" alt="Fabienne">
                        </v-avatar>
                        <b class="ml-2">Pr Hinde Iraqi</b>
                    </a><br>
                    <span class="sessionTitre ml-14">Place des ADO dans les recommandations</span>
                    <v-row class="mt-1">
                        <v-col cols="12" md="12" sm="12" align="left" class="" style="text-justify: inter-word;">
                            <b style="color:#1976D2">Modérateurs :</b><b class="ml-1" style="color:#ABA09C">El H.El Mahdaoui -</b>
                                <b class="ml-1" style="color:#ABA09C"> K.Hamamsi</b>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="2" sm="12" align="right">
                   <img width="120" src="../../assets/sponsors/sponsor-16.png" alt="Fabienne"> 
                </v-col>
          </v-row>
        </v-timeline-item>
        <v-timeline-item  color="indigo darken-3" small>
            <v-row class="pt-1">
                <v-col cols="12">
                    <strong>13:30-14:45</strong>
                </v-col>
                <v-col  cols="10" align="center" >
                    <strong style="color:#F38F3C">DÉJEUNER</strong>
                </v-col>
            </v-row>
        </v-timeline-item>
        <v-timeline-item  color="amber darken-4" small>
            <v-row class="pt-1">
                <v-col cols="12">
                    <strong>15:00-15:30</strong>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" align="left" >
                    <a href="#speakers" @click="slideTo(17)" style="text-decoration:none">
                        <v-avatar>
                        <img src="/speakers/houssaini.jpg" alt="Fabienne">
                        </v-avatar>
                        <b class="ml-2">Pr Tarik Sqalli Houssaini</b>
                    </a><br>
                    <span class="sessionTitre ml-14">Quelle place de la télémédecine dans le suivi des patients atteints de néphropathie diabétique ?</span>
                    <v-row class="mt-1">
                        <v-col cols="12" md="12" sm="12" align="left" class="" style="text-justify: inter-word;">
                            <b style="color:#1976D2">Modérateurs :</b>
                                <b class="ml-1" style="color:#ABA09C"> S.Rizk - El H.El Mahdaoui - O.Nour</b>
                        </v-col>
                    </v-row>
                </v-col>
          </v-row>
        </v-timeline-item>
        <v-timeline-item  color="amber darken-4" small>
            <v-row class="pt-1">
                <v-col cols="12">
                    <strong>15:30-16:00</strong>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="10" sm="12" align="left" >
                    <a href="#speakers" @click="slideTo(18)" style="text-decoration:none">
                        <v-avatar>
                        <img src="/speakers/abkari.jpg" alt="Fabienne">
                        </v-avatar>
                        <b class="ml-2">Pr Abdelhak Abkari</b>
                    </a><br>
                    <span class="sessionTitre ml-14">n Dysbiose et dérèglements métaboliques ; exemple du diabète de type 2</span>
                    <v-row class="mt-1">
                        <v-col cols="12" md="12" sm="12" align="left" class="" style="text-justify: inter-word;">
                            <b style="color:#1976D2">Modérateurs :</b>
                                <b class="ml-1" style="color:#ABA09C"> A.Samir - A.Lachhab</b>
                        </v-col>
                    </v-row>
                </v-col>
          </v-row>
        </v-timeline-item>
        <v-timeline-item  color="amber darken-4" small>
            <v-row class="pt-1">
                <v-col cols="12">
                    <strong>16:00-16:30</strong>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6" sm="12" align="left" >
                    <a href="#speakers" @click="slideTo(20)" style="text-decoration:none">
                        <v-avatar>
                        <img src="/speakers/benomar.jpg" alt="Fabienne">
                        </v-avatar>
                        <b class="ml-2">Dr Kanza Benomar</b>
                    </a><br>
                    <span class="sessionTitre ml-14">Flash actualités sur le diabète et l’obésité en 2022</span>
                    <v-row class="mt-1">
                        <v-col cols="12" md="12" sm="12" align="left" class="" style="text-justify: inter-word;">
                            <b style="color:#1976D2">Modérateurs :</b>
                                <b class="ml-1" style="color:#ABA09C"> A.El Azrak - D.Rochd</b>
                        </v-col>
                    </v-row>
                </v-col>
          </v-row>
        </v-timeline-item>
        <v-timeline-item  color="amber darken-4" small>
            <v-row class="pt-1">
                <v-col cols="12">
                    <strong>16:30-17:00</strong>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="10" sm="12" align="left" >
                    <a href="#speakers" @click="slideTo(21)" style="text-decoration:none">
                        <v-avatar>
                        <img src="/speakers/alami.jpg" alt="Fabienne">
                        </v-avatar>
                        <b class="ml-2">Pr Mohammed Alami</b>
                    </a><br>
                    <span class="sessionTitre ml-14">Insuffisance cardiaque : Du nouveau et encore du nouveau</span>
                    <v-row class="mt-1">
                        <v-col cols="12" md="12" sm="12" align="left" class="" style="text-justify: inter-word;">
                            <b style="color:#1976D2">Modérateurs :</b>
                                <b class="ml-1" style="color:#ABA09C"> A.Bakri - El H.El Mahdaoui - N.Essekkat</b>
                        </v-col>
                    </v-row>
                </v-col>
          </v-row>
        </v-timeline-item>
        <v-timeline-item  color="indigo darken-3" small>
            <v-row class="pt-1">
                <v-col cols="12">
                    <strong>17:00-17:30</strong>
                </v-col>
                <v-col  cols="11" align="center" >
                    <strong style="color:#F38F3C">PAUSE CAFÉ DÎNATOIRE - VISITE DES STANDS</strong>
                </v-col>
            </v-row>
        </v-timeline-item>
        <v-timeline-item  color="amber darken-4" small>
            <v-row class="pt-1">
                <v-col cols="12">
                    <strong>17:30-18:00</strong>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" align="left" >
                    <a href="#speakers" @click="slideTo(25)" style="text-decoration:none">
                        <v-avatar>
                        <img src="/speakers/harmouch.png" alt="Fabienne">
                        </v-avatar>
                        <b class="ml-2">Pr Hicham Harmouche</b>
                    </a><br>
                    <span class="sessionTitre ml-14">Nos patients diabétiques sont-ils à jours de leurs vaccinations ?</span>
                    <v-row class="mt-1">
                        <v-col cols="12" md="12" sm="12" align="left" class="" style="text-justify: inter-word;">
                            <b style="color:#1976D2">Modérateurs :</b>
                                <b class="ml-1" style="color:#ABA09C"> M.Benchekroune - F.Nibani</b>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-timeline-item>
        <v-timeline-item  color="amber darken-4" small>
            <v-row class="pt-1">
                <v-col cols="12">
                    <strong>18:00-18:30</strong>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" align="left" >
                    <a href="#speakers" @click="slideTo(0)" style="text-decoration:none">
                        <v-avatar>
                        <img src="/speakers/avt.png" alt="Fabienne">
                        </v-avatar>
                        <b class="ml-2">Pr Ahmed Anas Guerboub</b>
                    </a><br>
                    <span class="sessionTitre ml-14">Diabète Type 2 et Système Nerveux Autonome</span>
                    <v-row class="mt-1">
                        <v-col cols="12" md="12" sm="12" align="left" class="" style="text-justify: inter-word;">
                            <b style="color:#1976D2">Modérateurs :</b>
                                <b class="ml-1" style="color:#ABA09C">  El H.El Mahdaoui - K.Hamamsi</b>
                        </v-col>
                    </v-row>
                </v-col>
          </v-row>
        </v-timeline-item>
        <v-timeline-item  color="amber darken-4" small>
            <v-row class="pt-1">
                <v-col cols="12">
                    <strong>18:30-19:00</strong>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" align="left" >
                    <a href="#speakers" @click="slideTo(22)" style="text-decoration:none">
                        <v-avatar>
                        <img src="/speakers/azrak.jpg" alt="Fabienne">
                        </v-avatar>
                        <b class="ml-2">Dr Nadia El Azrak</b>
                    </a><br>
                    <span class="sessionTitre ml-14">Artériopathie oblitérante des membres inférieurs chez les patients diabétiques.</span>
                    <v-row class="mt-1">
                        <v-col cols="12" md="12" sm="12" align="left" class="" style="text-justify: inter-word;">
                            <b style="color:#1976D2">Modérateurs :</b>
                                <b class="ml-1" style="color:#ABA09C"> A.El Azrak - D.Rochd</b>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-timeline-item>
        <v-timeline-item  color="amber darken-4" small>
            <v-row class="pt-1">
                <v-col cols="12">
                    <strong>19:00-19:30</strong>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" align="left" >
                    <a href="#speakers" @click="slideTo(23)" style="text-decoration:none">
                        <v-avatar>
                            <img src="/speakers/hamaoui.jpg" alt="Fabienne">
                        </v-avatar>
                        <b class="ml-2">Pr Youssef El HamaouI</b>
                    </a><br>
                    <span class="sessionTitre ml-14">Sorcelleries ou maladies psychiatriques ? Guerre entre charlatans et médecins</span>
                    <v-row class="mt-1">
                        <v-col cols="12" md="12" sm="12" align="left" class="" style="text-justify: inter-word;">
                            <b style="color:#1976D2">Modérateurs :</b>
                                <b class="ml-1" style="color:#ABA09C"> N.Essekkat - A.Bakri</b>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-timeline-item>
        </v-timeline>
    </div>
</template>
<script>
export default {
    
    metaInfo() {
        return{
        
        
      }
    },
    methods: {
        slideTo(num){
            this.$emit('gotoslide',num)
        }
    },

}
</script>
<style >



</style>