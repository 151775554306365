<template>
    <div>
        <v-container grid-list-xs>
        <v-row>
            <v-col align="center">
            <h1 style="color:#F38E3C"  class="compHead">
                SPONSORS
            </h1>
            <v-divider></v-divider>
            </v-col>
        </v-row>
        <v-row class="mt-2 mb-5">
            <v-col cols="12" align="center">
            <v-layout >
                <v-flex  class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-01.png" width="200" >
                </v-flex>
                <v-flex  class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-02.png" width="200" >
                </v-flex>
                <v-flex class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-03.png" width="200" >
                </v-flex>
                <v-flex  class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-04.png" width="200" >
                </v-flex>
                <v-flex  class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-05.png"  width="200" >
                </v-flex>
            </v-layout>
            </v-col>
            <v-col cols="12" align="center">
            <v-layout >
                <v-flex  class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-06.png" width="200" >
                </v-flex>
                <v-flex  class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-07.png" width="200" >
                </v-flex>
                <v-flex class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-08.png" width="200" >
                </v-flex>
                <v-flex  class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-09.png" width="200" >
                </v-flex>
                <v-flex  class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-10.png"  width="200" >
                </v-flex>
            </v-layout>
            </v-col>
            <v-col cols="12" align="center">
            <v-layout >
                <v-flex  class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-11.png" width="200" >
                </v-flex>
                <v-flex  class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-12.png" width="200" >
                </v-flex>
                <v-flex class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-13.png" width="200" >
                </v-flex>
                <v-flex  class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-14.png" width="200" >
                </v-flex>
                <v-flex  class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-15.png"  width="200" >
                </v-flex>
            </v-layout>
            </v-col>
            <v-col cols="12" align="center">
            <v-layout >
                <v-flex  class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-16.png" width="200" >
                </v-flex>
                <v-flex  class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-17.png" width="200" >
                </v-flex>
                <v-flex class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-18.png" width="200" >
                </v-flex>
                <v-flex  class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-19.png" width="200" >
                </v-flex>
                <v-flex  class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-20.png"  width="200" >
                </v-flex>
            </v-layout>
            </v-col>
            <v-col cols="12" align="center">
            <v-layout >
                <v-flex  class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-21.png" width="200" >
                </v-flex>
                <v-flex  class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-22.png" width="200" >
                </v-flex>
                <v-flex class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-23.png" width="200" >
                </v-flex>
                <v-flex  class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-24.png" width="200" >
                </v-flex>
                <v-flex  class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-25.png"  width="200" >
                </v-flex>
            </v-layout>
            </v-col>
            <v-col cols="12" align="center">
            <v-layout >
                <v-flex  class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-26.png" width="200" >
                </v-flex>
                <v-flex  class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-27.png" width="200" >
                </v-flex>
                <v-flex class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-28.png" width="200" >
                </v-flex>
                <v-flex  class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-29.png" width="200" >
                </v-flex>
                <v-flex  class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-30.png"  width="200" >
                </v-flex>
            </v-layout>
            </v-col>
            <v-col cols="12" align="center">
            <v-layout >
                <v-flex  class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-31.png" width="200" >
                </v-flex>
                <v-flex  class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-32.png" width="200" >
                </v-flex>
                <v-flex class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-33.png" width="200" >
                </v-flex>
                <v-flex  class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-34.png" width="200" >
                </v-flex>
                <v-flex  class="lg5-custom ps-1">
                <img  class="img-responsive " src="../../assets/sponsors/sponsor-35.png"  width="200" >
                </v-flex>
            </v-layout>
            </v-col>
        </v-row>
        </v-container>  
    </div>
</template>
<script>
export default {
    data() {
        return {
            
        }
    },
}
</script>
<style>

@media (max-width: 767px) {
    .partnername{
      font-size: 1rem!important;  
    }
}

</style>