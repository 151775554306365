<template>
  <v-container>
      <v-row>
          <v-col align="center">
              <h1 class="propsHead compHead" style="color:#F38E3C"  >
                  Mot du président
              </h1>
              <v-divider></v-divider>
          </v-col>
      </v-row>
      <v-row class="mb-5">
          <v-col cols="12">
              <v-card  class="rounded-xl" elevation="19" data-aos-once="true" data-aos="fade-down" data-aos-duration="1500">
                  <v-row>
                      <v-col cols="12" md="2" sm="12"  class="person  text-center p-md-5 p-sm-3">
                              <img src="/speakers/nordin.jpg" alt="Image" class="img-fluidmb-5 sp1Img" style="width: 150px">
                      </v-col>
                      <v-col  cols="12" md="10" sm="12" class="text-left pa-10 text">
                        Chères consœurs, chers confrères,<br>
                        Cette année, L’ADN organise son neuvième congrès destiné aux
                        Médecins diplômés en diabétologie, Nutrition et Éducation Thérapeutique
                        au Royal Tulipe à Tanger la capitale du nord du 29 Septembre au 02
                        Octobre 2022.<br>
                        La thématique du congrès de cette année est : Le futur en diabétologie
                        est-il déjà présent ? <br>
                        Animé par nos orateurs Nationaux et Internationaux, les objectifs
                        de ce congrès sont :<br>
                        - Mettre à jour notre savoir-faire et enrichir nos connaissances
                        scientifiques et ce, dans le cadre du développement professionnel
                        continu, destiné aux Médecins diplômés en Diabétologie-Nutrition
                        et à tous les médecins généralistes et Médecins de famille.<br>
                        - Tisser des partenariats avec des sociétés savantes à l’échelle
                        nationale et internationale afin d’améliorer la prise en charge des
                        patients diabétiques tout en intégrant une place incontournable à
                        l’éducation thérapeutique.<br>
                        Je tiens à remercier :<br>
                        - Nos éminents orateurs Nationaux et Internationaux pour leur
                        participation et animation active à notre congrès.<br>
                        - Mr le doyen de la Faculté de médecine et de Pharmacie de Fès
                        pour sa compréhension du changement du lieu du congrès de la
                        faculté de médecine, de pharmacie et de médecine dentaire de Fès
                        où notre Congrès à vue le jour et qui restera dans nos mémoires.<br>
                        - L’ensemble des laboratoires de l'industrie pharmaceutique pour
                        leur engagement et leur soutien.<br>
                        - Mes remerciements vont également à nos chers confrères et
                        consœurs pour leur présence et leur fidélité à notre association.<br>
                        Mes remerciements chaleureux à ceux et celles qui ont contribué
                        à la réussite de cette édition de l’ADN<br><br>
                        <b >Dr NOUREDDINE ESSEKKAT Président de l’ADN</b>
                      </v-col>
                  </v-row>
              </v-card>
          </v-col>
      </v-row>
  </v-container>
</template>
<script>
export default {
data() {
  return {
    more:false
  }
},
}
</script>
<style >
.propo{
  text-align: center;
}

@media all and (max-width: 768px) {

.propsHead{
  font-size: 1.5rem;
  padding: .75rem;
  color:#EC5713
}
}
</style>